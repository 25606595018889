// scripts to innerpages
// if ($('#page__x').length) {}
// headerHome
if ($('.l-headerHome').length) {
    var swiper__headerHome = new Swiper('.swiper__headerHome', {
        speed: 400,
        spaceBetween: 0,
        autoplay: 5000,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });

    $('.linkScroll').on('click', function(e) {
        e.preventDefault();
        goToSection__scroll('#page__home', 100, 50, 700, 10);
    });

    var swiper__institucional = new Swiper('.swiper-container', {
        speed: 400,
        spaceBetween: 0,
        autoplay: 5000,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    });
}